<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    listPage,
    updateMainTypeQyzt,
    deletMainType,
    addMainType,
    editMainType,
  } from "@/api/admin/base/mainType.js"
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,

    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "基础大类",
            active: true
          }
        ],
        exportTitle: "考试类别导出",
        importTitle: "考试类别导入",
        showmodal: false,
        dialogTitle: "添加考试类别",
        module: "KSDL",
        disabled: false,
        tableList: [],
        allDataList: [],
        addForm: {
          lbbm: "",
          lbmc: "",
          lbsx: "资格类",
          qyzt: true,
          pxh: 0,
        },
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletMainType(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        listPage(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateMainTypeQyzt({
          sid: sid,
          qyzt: qyzt
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '状态修改成功！'
            })
          }
        })
      },
      // 添加类别
      addMaintype() {
        let formDate = {
          lbbm: this.addForm.lbbm,
          lbmc: this.addForm.lbmc,
          lbsx: this.addForm.lbsx,
          qyzt: this.addForm.qyzt,
          pxh: this.addForm.pxh
        }
        if (formDate.lbmc) {
          addMainType(formDate).then(res => {
            if (res.status) {
              this.editSuccess()
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '类别名称不能为空!'
          });
        }

      },
      editMaintype() {
        let formDate = {
          lbbm: this.addForm.lbbm,
          lbmc: this.addForm.lbmc,
          lbsx: this.addForm.lbsx,
          qyzt: this.addForm.qyzt,
          pxh: this.addForm.pxh,
          sid: this.addForm.sid
        }
        editMainType(formDate).then(res => {
          if (res.status) {
            this.editSuccess()
          }
        })
      },
      editSuccess() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {
          lbbm: "",
          lbmc: "",
          lbsx: "资格类",
          qyzt: true,
          pxh: 0,
        }
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加考试类别"
        this.addForm = {
          lbbm: "",
          lbmc: "",
          lbsx: "资格类",
          qyzt: true,
          pxh: 0,
        }
        this.getBm(this.module, "dl").then(e => {
          this.addForm.lbbm = e
        })

      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改考试类别"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editMaintype()
        } else {
          this.addMaintype()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      // 数据导入之后刷新列表
      uploadSuccess() {
        this.getList()
      }
    },
    mounted() {
      this.getList()
    }

  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加类别</b-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>

              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 7%;">类别编码</th>
                    <th>类别名称</th>
                    <th style="width: 8%;">属性</th>
                    <th style="width: 8%;">是否启用</th>
                    <th style="width: 8%;">排序号</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.lbbm}}</td>
                    <td>
                      <div class="font-blue" @click='editItem(obj)'>{{obj.lbmc}}</div>
                    </td>
                    <td> {{obj.lbsx}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.pxh}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.lbmc)"></i>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <!-- 添加类别 -->
    <b-modal id="addClass" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <div class="grey-time mb-3">说明：考试类别是系统的基础数据，主要定义考试分类。</div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>类别编码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="6" :disabled="disabled" placeholder="类别编码，2位不能重复"
                  v-model="addForm.lbbm" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>类别名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" placeholder="请输入类别名称" maxlength="50" v-model="addForm.lbmc"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>类别属性：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.lbsx">
                  <option value="资格类">资格类</option>
                  <option value="水平类">水平类</option>
                  <option value="选拔类">选拔类</option>
                  <option value="其他类">其他类</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.qyzt"
                    value=true class="form-check-input"><label for="exampleRadios1" class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.qyzt"
                    value=false class="form-check-input"><label for="exampleRadio1" class="form-check-label">不启用</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>排序数值：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input id="number" value="1" type="number" name="number" v-model="addForm.pxh" class="w-100 h30"
                  placeholder="序号越小排序越靠前">
                </b-form-input>

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 导出 -->
    <!-- 弹窗结束-->

  </Layout>
</template>
